import React from 'react';
import './Home.scss';

const Home = () => {
    return (
        <React.Fragment>
            <header
                className="
                bg-purple
                d-flex
                flex-wrap
                align-items-center
                justify-content-center justify-content-md-between
                py-3
                mb-5
                border-bottom
            "
            >
                <a
                    href="/"
                    className="navbar-brand align-items-center col-md-2 mb-md-0 ms-5"
                >
                    <img
                        className="img img-responsive"
                        src="./images/logomajor1.png"
                        alt="logo1"
                    />
                </a>

                <ul
                    className="
                    nav
                    col-md-auto
                    mb-2
                    justify-content-center
                    mb-md-0
                "
                >
                    <li>
                        <a href="/#" className="nav-link px-2 text-white">
                            Home
                        </a>
                    </li>
                    <li>
                        <a
                            href="#aboutNFT"
                            className="nav-link px-2 text-white"
                        >
                            About
                        </a>
                    </li>
                    <li>
                        <a
                            href="#rarityChart"
                            className="nav-link px-2 text-white"
                        >
                            Rarity chart
                        </a>
                    </li>
                </ul>

                <div className="col-md-2 text-end"></div>
            </header>

            <section className="text-center">
                <h1>MAJORPUNK</h1>
                <div className="container mt-5 mb-4">
                    <img
                        className="img mb-2 me-1"
                        src="elements/70.png"
                        alt="photo1"
                    />
                    <img
                        className="img mb-2 me-1"
                        src="elements/221.png"
                        alt="photo2"
                    />
                    <img
                        className="img mb-2"
                        src="elements/157.png"
                        alt="photo3"
                    />
                </div>
            </section>

            <main className="mb-5" id="aboutNFT">
                <div className="container text-center justify-content-md-center">
                    <div className="offset-md-1 col-md-10">
                        <h3 className="text-uppercase mt-4 mb-4">
                            A collection of 209 uniques NFTs powered by Solana
                            available on secondary market
                        </h3>

                        <a
                            className="btn btn-lg btn-primary me-2 mb-2"
                            href="https://magiceden.io/marketplace/majorpunknft"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Buy on Magic Eden
                        </a>

                        <a
                            className="btn btn-lg btn-primary me-2 mb-2"
                            href="https://digitaleyes.market/collections/MajorPunk"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Buy on Digital Eyes
                        </a>

                        <br />
                        <br />

                        <p className="fs-4">
                            Major and No limits community are pleased to present
                            their first NFT collection.
                            <br />
                            <br />
                            On the occasion of the 2000+ members, a series of
                            209 NFTs will be randomly generated and available at
                            the mint, and then to the secondary market!
                            <br />
                            <br />
                            Many great prizes are to be won, whether it is
                            formations such as Oasis, Vortex, or Orbital, but
                            also the sending of a cake (a real one), airdrop,
                            payment of an electricity bill... and many other
                            offered by Major.
                            <br />
                            <br />
                            Owning an NFT Major of this series allows us to
                            remember where we came from because we already know
                            that we have taken the right path to the the moon!
                        </p>
                        <p className="fs-4">
                            We chose to launch this collection with an
                            educational purpose, that allowed us to:{' '}
                        </p>
                        <ul className="fs-4 mt-3 mb-3">
                            <li className="text-start">
                                learning NFT creation by skining in the game
                            </li>
                            <li className="text-start">
                                the creation and implementation of a MINT page
                            </li>
                            <li className="text-start">
                                NFT deployment on the Solana blockchain
                            </li>
                            <li className="text-start">
                                having fun into pixel art workshop
                            </li>
                        </ul>
                    </div>
                </div>
            </main>

            <section className="pt-5 text-center" id="rarityChart">
                <h1 className="mb-5">Rarity chart</h1>
                <div className="container">
                    <h2>Unique</h2>
                    <div className="row">
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/95.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Oasis</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/192.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Vortex</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/130.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Atlantis</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/157.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Orbital</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/55.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Halo</p>
                            </div>
                        </div>

                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/169.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Pmon Addict</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/18.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Cook</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/207.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Fantom</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/71.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Shitcoin</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/182.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Santa</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/138.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Red temalerat</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/155.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Blue temalerat</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/164.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Ochre temalerat</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/70.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Pikachu</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/211.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Easter egg</p>
                            </div>
                        </div>

                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/14.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Checoin</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/120.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Dark Major</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/168.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Whale</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/16.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Spiderman</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/24.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Ironman</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/221.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">RugPull</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h2>Background</h2>
                    <div className="row">
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg06 - Copie.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Red : 8,56%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg02.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Farming : 7,66%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg06 - Copie (3).png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Gray : 7,21%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg10.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Stars : 6,76%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg09.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Dust : 6,76%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg06.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Blue : 6,76%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg11.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Plain : 6,31%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg07.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Moon : 5,86%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg03.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Sunset 1 : 5,86%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg08.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Gaming : 5,41%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg04.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Sunset 2 : 5,41%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg01.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Weird : 4,95%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/bg06 - Copie (2).png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Yellow : 4,5%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/town.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">City : 4,05%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h2>Face</h2>
                    <div className="row">
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses04.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">VR : 9,01%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses02.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Pirat : 7,21%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses07.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Salad : 6,31%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses06.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Covid mask : 5,86%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses01.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Glasses : 5,86%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses10.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">
                                    Super glasses : 5,41%
                                </p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses08.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Manga : 5,41%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses03.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Monocle : 4.05%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses09.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">bearb : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/glasses05.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">BCBG : 2,7%</p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h2>Hair</h2>
                    <div className="row">
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair16.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Punkhair : 7,21%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair04.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Emir : 5,41%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair12.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Grey hat : 4,5%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair01.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Banana : 4,05%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair10.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Unicorn hat : 4,05%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair14.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Long hair : 4,05%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair09.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Macdo hat : 3,6%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair05.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Captain : 3,6%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair11.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Nolimits hat : 3,6%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair07.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Luigi hat : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair08.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Mario hat : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair03.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Afro : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair06.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Green hat : 2,7%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair15.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">No hair : 2,25%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hair13.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Wizard hat : 1,8%</p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h2>Mask</h2>
                    <div className="row">
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/hero.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Hero : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/mask01.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Evil 1 : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/mask07.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Anon :  2.7%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/mask02.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Evil 2 : 2,7%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/mask06.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Clown : 2,25%</p>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <h2>Access</h2>
                    <div className="row">
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc08.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Pencil : 4,5%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc04.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Ventouse : 3,6%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc02.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Knife : 3,6%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc06.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Flag : 3,15%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc05.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Stop : 2,7%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc01.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Saber : 2,7%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc07.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">
                                    Time machine : 2.25%
                                </p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <img
                                src="elements/acc03.png"
                                className="card-img-top img-fluid mx-auto"
                                alt="img"
                            />
                            <div className="card-body">
                                <p className="card-text">Fork : 1,35%</p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                        <div className="card col-md-2 col-xs-12">
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Home;
